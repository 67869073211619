import React, { useState } from 'react';
import { Seo } from '@app/components';
import {
  AlmostHero,
  Contact,
  CreateAccount,
  Features,
  Footer,
  Header,
  MainHero,
  News,
  Testimonials,
  TryForFree
} from '@app/components/template';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { Modal } from 'react-bootstrap';
import { AccountData, CreateState } from '@app/model';
import { signUp } from '@app/services';
import index from '@app/images/index.jpg';

export default (props: any) => {
  const intl = useIntl();

  const postList = props.data.allMarkdownRemark.edges
    .map((edge: any) => edge.node.frontmatter)
    .filter((frontmatter: any) => frontmatter.language === intl.locale)
    .map((frontmatter: any) => ({
      path: frontmatter.path,
      title: frontmatter.title,
      date: frontmatter.date,
      image: frontmatter.image.childImageSharp.fluid
    }));

  const [show, setShow] = useState(false);
  const [state, setState] = useState(CreateState.INITIAL);
  const onSubmit = async (accountData: AccountData) => {
    setState(CreateState.IN_PROGRESS);
    try {
      await signUp(accountData);
      setState(CreateState.SUCCESS);
    } catch (e) {
      setState(CreateState.ERROR);
    }
  };
  const onCancel = () => {
    setShow(false);
    setState(CreateState.INITIAL);
  };
  const showModal = () => {
    setState(CreateState.INITIAL);
    setShow(true);
  }

  return (
    <>
      <Seo title={ intl.formatMessage({ id: 'seo_title_index' }) }
           image={index}
           description={ intl.formatMessage({ id: 'seo_description_first' }) } />
      <div id="main" className="main-content-wraper">
        <div className="main-content-inner">
          <Header />
          <MainHero tryForFreeClick={() => showModal()} />
          <AlmostHero />
          <Features />

          <TryForFree buttonClick={ () => showModal() }
                      title={ intl.formatMessage({ id: 'index_sounds_good' }) }
                      description={ intl.formatMessage({ id: 'index_sign_up_and_find_out' }) } />
          <Testimonials />
          <TryForFree buttonClick={ () => showModal() }
                      title={ intl.formatMessage({ id: 'index_what_if' }) }
                      description={ intl.formatMessage({ id: 'index_we_will_return_money' }) } />

          <News postList={ postList } />

          <Contact />
          <Footer />

          <Modal show={ show } onHide={ () => setShow(false) }>
            <CreateAccount state={ state }
                           submit={ (payload: AccountData) => onSubmit(payload) }
                           cancel={ () => onCancel() } />
          </Modal>
        </div>
      </div>
    </>
  );
};
export const query = graphql`
    {
        allMarkdownRemark{
            edges {
                node {
                    excerpt(pruneLength: 250)
                    frontmatter {
                        path
                        date
                        title
                        language
                        image {
                            childImageSharp {
                                resize(width: 1500, height: 1500) {
                                    src
                                }
                                fluid(maxWidth: 786) {
                                    src
                                    srcSet
                                    sizes
                                    aspectRatio
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;